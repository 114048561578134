const getBalance = (state) => state.game.balance;
const getBetAmount = (state) => state.game.betAmount;
const getBetIndex = (state) => state.game.bets.indexOf(state.game.betAmount);
const getGameState = (state) => state.game.gameState;
const getAvailableBets = (state) => state.game.betsAvailable;
const getIsAutoSpin = (state) => state.game.autospin;
const getCurrencyType = (state) => state.game.currency;
const getWinAmount = (state) => state.game.winAmount;
const getLastWinAmount = (state) => state.game.lastWinAmount;
const getCurrencyInfo = (state) => state.game.currencyInfo;
const getBetMinMax = (state) => ({ min: state.game.betMin, max: state.game.betMax });
const getStopReels = (state) => state.game.stopReels;
const getWinLine = (state) => state.game.winLine;
const getPaytable = (state) => state.game.paytable;
const getFreespinsEnabled = (state) => state.game.freespinsEnabled;
const getFreespinsCount = (state) => state.game.freespinsCount;
const getFreespinsTotalWin = (state) => state.game.totalWinFS;

const getAvailableSymbols = (index) => (state) => state.game.availableSymbols[index];

export default {
  getBalance,
  getBetAmount,
  getBetIndex,
  getAvailableBets,
  getGameState,
  getIsAutoSpin,
  getCurrencyType,
  getWinAmount,
  getLastWinAmount,
  getCurrencyInfo,
  getBetMinMax,
  getStopReels,
  getWinLine,
  getPaytable,
  getFreespinsEnabled,
  getFreespinsCount,
  getFreespinsTotalWin,
  getAvailableSymbols,
}
