import { Back, Linear, TimelineMax } from 'gsap';
import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import actions from '../../redux/game/actions';

const eAnimationName = {
  EAN_IDLE: 'animation',
};

export default class ControllerReel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.index = parseInt(this.name.replace('reel_', ''));

    this.speed = 1; // 2
    this.startSpeed = 0.2; // 0.1
    this.needStop = false;
    this.rollings = 0;
    this.stopCallback = () => {};
    this.checkAnticipator = () => {};
    this.stopped = false;

    // this.anticipator = this.createAnticipator();

    this.config = {
      symbols: {
        height: 235
      }
    };

    this.availableSymbols = storeObserver.addListenerAsNewAndGetValue(selectors.getAvailableSymbols(this.index), this.onSymbolsChanged);

    this.symbols = [];
    this.stopSymbolId = 0;
    for (let i = 0; i < 3; i++) {
      const item = this.getChildByName(`element_${i}`);
      item.setSymbol(this.availableSymbols[i]);
      this.symbols.push(item);
    }

    this.animation = {
      start: null,
      roll: null,
      stop: null,
      stopEnd: null
    }
  }

  createAnticipator() {
    const animation = animationsLoader.createAnimation(eAnimationType.EST_ANTICIPATOR);
    animation.state.addAnimation(0, eAnimationName.EAN_IDLE, true, 0);
    animation.x = 365;
    animation.y = 350;

    animation.visible = false;

    this.addChildAt(animation, 3);

    return animation;
  }

  showAnticipator(show) {
    // this.anticipator.visible = show;
  }

  spin() {
    this.stopped = false;
    this.needStop = false;
    this.rollings = 0;
    this.animation.start.invalidate();
    this.animation.start.restart();
  }

  roll() {
    this.cycleSymbols();
    this.animation.roll.invalidate();
    this.animation.roll.restart();
  }

  rollBeforeStop() {
    if(this.needStop) {
      this.continueRollingAfterResponse();
      this.stopped = true;
      return;
    }
    this.cycleSymbols();

    this.animation.roll.invalidate();
    this.animation.roll.restart();
  }

  continueRollingAfterResponse() {
    this.cycleSymbols();
    this.stopRolling();
  }

  stopRolling() {
    this.symbols[1].setSymbol(this.stopSymbol);
    //this is hack, cause invalidate + reset dispatch onComplete callback

    const temp = this.stopCallback;
    const anticipatorTemp = this.checkAnticipator;
    this.stopCallback = () => {};
    this.checkAnticipator = () => {};
    this.animation.stop.invalidate();
    this.animation.stop.restart();
    this.stopCallback = temp;
    this.checkAnticipator = anticipatorTemp;

    ControllerSounds.playSound(eSoundType.EST_REEL_STOP);

    store.dispatch(actions.setStoppingReel(this.index));
  }

  setupAnimations(delay) {
    this.setupStartAnimation(delay);
    this.setupRollAnimation();
    this.setupStopAnimation();
  }

  setupStartAnimation(delay) {
    this.animation.start = new TimelineMax({ paused: true });
    this.animation.start.to(this.symbols, this.startSpeed, {
      y: '-=' + (this.config.symbols.height / 2),
      yoyo: true,
      repeat: 1,
      delay: delay,
      onComplete: this.roll,
      callbackScope: this
    });
  }

  setupRollAnimation() {
    this.animation.roll = new TimelineMax({ paused: true });
    this.animation.roll.to(this.symbols, 0.07 / this.speed, {
      y: '+=' + this.config.symbols.height,
      ease: Linear.easeNone,
      onComplete: this.rollBeforeStop,
      callbackScope: this,
    });
  }

  setupStopAnimation() {
    this.animation.stop = new TimelineMax({ paused: true });
    this.animation.stop
      .to(this.symbols, this.startSpeed * 1.5, {
        y: `+=${this.config.symbols.height}`, //(this.config.symbols.height / 2)
        ease: Back.easeOut.config(3)
      })

    this.animation.stop.eventCallback('onComplete', () => {
      this.stopCallback(this);
      this.checkAnticipator(this);
    });
  }

  onSymbolsChanged = (data) => {
    this.availableSymbols = data;
  };

  async forceStop(type, stopCallback) {
    if(this.stopped) return;

    const delay = 10 * this.index;

    setTimeout(() => {
      this.showAnticipator(false);
      // ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);

      // this.animation.start.pause();
      // this.animation.roll.pause();

      this.needStop = true;
      this.stopSymbol = type;
      this.stopCallback = stopCallback;

      // this.symbols.forEach((symbol, index) => {
      //   symbol.y = (this.config.symbols.height * index) - this.config.symbols.height;
      // });
      //
      // this.stopRolling();
      // ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);
    }, delay);

  }

  stop(type, id, stopCallback, checkAnticipator) {
    this.needStop = true;
    this.stopSymbolId = id;
    this.stopSymbol = type;
    this.stopCallback = stopCallback;
    this.checkAnticipator = checkAnticipator;
  }

  cycleSymbols() {
    this.availableSymbols.unshift(this.availableSymbols.pop());
    const item = this.symbols.pop();
    item.setSymbol(this.availableSymbols[0]);
    this.symbols.unshift(item);
    this.symbols.forEach((symbol, index) => {
      symbol.y = this.config.symbols.height * (index - 1);
    });
  }

  playSymbolAnimation() {
    this.symbols[1].animateSymbol();
  }
}
