import './import';
import '../css/index.css'
import '../css/fonts.css'
import 'pixi-spine';
// import './libs/fullscreen/fullscreen';
import './utils/number';
import {createStore, applyMiddleware, compose} from 'redux';
// import logger from 'redux-logger';
import reducer from './redux/reducers';
import StoreObserver from './redux/storeObserver';
import Game from './controllers/controllerGame';

import OPWrapperService from './libs/op-wrapper-service';
import wrapperConfig from './wrapperConfig';

const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composer = devtools ? devtools : compose;

// export const store = createStore(reducer, composer(applyMiddleware(logger)));
export const store = createStore(reducer);

export const storeObserver = new StoreObserver(store);

new OPWrapperService(wrapperConfig);

export const game = new Game();
