import selectors from "../../../redux/game/selectors";
import {store} from "../../../index";
import actions from '../../../redux/game/actions';
import {eGameState} from "../../../redux/game/reducer";
import {sleep} from "../../../utils/sleep";
import actionsModals from "../../../redux/modals/actions";
import eModalType from "../../popups/eModalType";
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotLastFreespinBehaviour extends ControllerBaseBehaviour{
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async delay => {
      await sleep(delay);

      store.dispatch(actions.setGameState(eGameState.EGS_SHOW_WIN));

      if(state.game.winAmount !== 0) {
        await sleep(3000);
      }

      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_FREE_SPINS_END }));

      await sleep(3000);

      const time = this._slotController.vines.show(0);

      await sleep(time);

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
    });
  }

}
