import ModalBase from "../base/modalBase";
import eModalType from "./eModalType";
import {modalWinHeadline, winAmountCounterStyle} from "../../fontStyles";
import {store} from "../../index";
import WinAmountCounter from "../winAmountCounter";
import actionsModals from "../../redux/modals/actions";
import selectors from '../../redux/game/selectors';

export default class ModalFreeSpinsEnd extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_FREE_SPINS_END;

    this.init();
  }

  init() {
    this.initListener();
    this.showBlackout();
    this.setTextStyle();
    this.on('click', this.onClick);
    // document.addEventListener('keydown', event => this.onKeyDown(event), false);
  }

  destroy() {
    super.destroy();
    this.off('click', this.onClick);
  }

  setAmount(value) {
    this.winAmountCounter = new WinAmountCounter(this.getChildByName('winCounter'), winAmountCounterStyle);
    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);
    this.winAmountCounter.animate(value, decimals);
    this.winAmountCounter.onStop = () => store.dispatch(actionsModals.closeModal({ type: this.type }))
  }

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if(!blackout) return;
    blackout.show(true, 0.3);
  }

  setTextStyle() {
    const youWinText = this.getChildByName('youWin');

    youWinText.text = window.OPWrapperService.localizations.getLocalizedText('game_total_win');
    youWinText.style = modalWinHeadline;
  }

  onClick() {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

  show(amount) {
    this.visible = true;
    this.setAmount(amount);
  }

  hide() {
    this.visible = false;
  }

  onModalDataChanged = (data) => {
    const state = store.getState().game;
    const totalWin = state.totalWinFS;
    data.visible ? this.show(totalWin) : this.hide();
  };

  onKeyDown(event) {
    if(event.keyCode === 32 && this.visible) { //32 - Space
      this.stop();
      this.winAmountCounter.stop();
    }
  }

  onClick() {
    this.stop();
    this.winAmountCounter.stop();
  }

  stop() {
    if(this.visible)
      store.dispatch(actionsModals.closeModal({ type: this.type }));
  }
}
