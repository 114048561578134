import FlashLib from 'flashlib';
import { store } from '../../index';
import { TweenMax } from 'gsap';
import {freeSpinsPanelValue} from "../../fontStyles";
import selectors from '../../redux/game/selectors';
import { formatCurrency } from '../../utils/currency';

export default class FreespinsPanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.label = this.getChildByName('label');
    this.labelTW = this.getChildByName('label_total_win');
    this.alpha = 0;
    this.isHide = true;

    const localizationManager = window.OPWrapperService.localizations;
    this._totalWin = 0;
    this._freespinsHeadline = this.getChildByName('headline_freespins');
    this._freespinsHeadline.text = localizationManager.getLocalizedText('game_spins_left');
    this._totalWinHeadline = this.getChildByName('headline_total_win');
    this._totalWinHeadline.text = localizationManager.getLocalizedText('game_total_win');

    this.init();
  }

  init() {
    const state = store.getState().game;

    if(!state.freespinsEnabled) {
      this._totalWin = 0;
    }

    this._totalWin = state.totalWinFS;

    this.label.style = this.labelTW.style = freeSpinsPanelValue;
  }

  update() {
    const freeSpinsCount = store.getState().game.freespinsCount || 0;
    this.label.text = freeSpinsCount.toString();
    this.labelTW.text = this.normalizeValue(this._totalWin).toString();
  }

  setValue() {
    const state = store.getState().game;

    if(!state.freeSpinsMax) return;

    this._totalWin = state.totalWinFS;
    this._totalWin = this.normalizeValue(this._totalWin);
    this.labelTW.text = this._totalWin.toString();
  }

  decrease() {
    const freeSpinsCount = store.getState().game.freespinsCount - 1;
    this.label.text = (freeSpinsCount).toString();
  }

  resetTotalWin() {
    this._totalWin = 0;
    this.labelTW.text = this._totalWin.toString();
  }

  show() {
    if(!this.isHide) return;
    this.isHide = false;
    TweenMax.to(this, 0.5, { alpha: 1 })
  }

  hide() {
    this.isHide = true;
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: () => this.resetTotalWin()  });
  }

  normalizeValue(value) {
    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    return formatCurrency(parseFloat(value), currencyInfo.decimal);
  }
}
