import { handleActions } from 'redux-actions';

import userActions from './actions';
import eModalType from '../../controllers/popups/eModalType';

const getInitialState = () => {
  const result = {};

  for (let key in eModalType) {
    result[eModalType[key]] = {
      type: eModalType[key],
      visible: false
    }
  }

  return result;
};

export default handleActions(
  {
    [userActions.showModal]: (state, { payload }) => {
      console.log('Show popup: ', payload.type);
      // const info = { ...state };
      // for (let key in info) {
      //   let item = info[key];
      //   item.visible = key === payload.type;
      //   item.data = payload.data;
      // }

      return {
        ...state,
        ...Object.fromEntries(Object.entries(state).map(([type, value]) => [type, {...value, visible: false, data: null}])),
        [payload.type]: {
          ...state[payload.type],
          visible: true,
          data: payload.data,
        }
      };
    },
    [userActions.closeModal]: (state, { payload }) => {
      console.log('Hide popup: ', payload.type);

      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          data: null,
          visible: false
        }
      };
    }
  },
  getInitialState()
);
