import '../../scss/styles.scss';
import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';
import { requestConfig } from '../api/rest';
import WebFont from 'webfontloader';
import AnimationsLoader from './animations/animationsLoader';
import ControllerStartScreen from './controllerStartScreen';
import OrientationBlocker from '../libs/orientationBlocker';
import ControllerTextField from '../textField/ControllerTextField';
import SoundsPreloader from '../controllers/sounds/SoundsPreloader';
import SoundManager from './sounds/SoundManager';
import EMAdapter from '../platforms/everyMatrix/adapter';
import Config from './Config';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;

    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();
    EMAdapter.init();
  }

  start() {
    this.container = document.getElementById('container');
    this.gameSize = {
      width: 2340,
      height: 1080
    };
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      resolution: window.devicePixelRatio < 2 ? window.devicePixelRatio : 2,
    };
    this.app = new PIXI.Application(this.applicationOptions);
    FlashLib.TextField = ControllerTextField;
    this.app.view.id = 'gameCanvas';
    this.container.appendChild(this.app.view);
    this.scaleData = {
      app: this.app,
      gameSize: this.gameSize
    };

    SoundsPreloader.startLoading();
    this.loadAssets();
    this.loadFonts();

    AnimationsLoader.startLoading(this.app, () => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    });

    requestConfig()
      .then(() => {
        console.log('Config received');
        this.configReceived = true;
        this.tryInitGame();
      });
  }

  loadAssets() {
    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLibAssets', process.env !== 'development' ? 'AssetsCombined.json' : 'FlashLibAssets.json', 'json');
    PIXI.Loader.shared.add('GoldFont', 'assets/fonts/GoldFont.xml', 'font');
    PIXI.Loader.shared.once('complete', () => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);
    PIXI.Loader.shared.on('error', this.onLoadingError.bind(this));
    PIXI.Loader.shared.load();

    PIXI.Loader.shared.on('progress', (data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: ['Ubuntu bold', 'Ubuntu regular', 'Myriad Pro', 'Inter Semi Bold'],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }

  tryInitGame() {
    if (this.fontsLoaded &&
      this.assetsLoaded &&
      this.configReceived &&
      this.animationsLoaded) {
      OrientationBlocker.init(OrientationBlocker.orientationType.LANDSCAPE);
      window.OPPreloader.hide();
      this.initGame();
    }
  }

  initGame() {
    SoundManager.init();
    EMAdapter.loadCompleted();

    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    this.app.stage.addChild(main);
    new ControllerStartScreen(main);
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config)
  }

}
