import {store} from "../../../index";
import selectors from "../../../redux/game/selectors";
import {sleep} from "../../../utils/sleep";
import actionsModals from "../../../redux/modals/actions";
import actions from "../../../redux/game/actions";
import {eGameState} from "../../../redux/game/reducer";
import { requestNewGame } from '../../../api/rest';
import eModalType from "../../popups/eModalType";
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotStartFreespinsBehaviour extends ControllerBaseBehaviour{
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async delay => {
      delay = this._slotController.vines.hide(delay);
      await sleep(delay);
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_FREE_SPINS }));
      await sleep(2000);
      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
      await requestNewGame();
    });
  }
}
