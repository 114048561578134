import en from '../assets/i18n/en';
import enSocial from '../assets/i18n/en-social';
import actionsModal from './redux/modals/actions';
import eModalType from './controllers/popups/eModalType';
import { store } from './index';

export default {
  buttons: [
    {
      type: 'settings',
      onClick: () => {
        store.dispatch(actionsModal.showModal({type: eModalType.EMT_SETTINGS}));
      },
      styles: ['settings']
    },
  ],
  bundle: 'junglegold',
  localizationBundle: 'junglegold',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
