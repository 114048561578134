import FlashLib from 'flashlib';
import eSymbolType from './eSymbolType';
import eAnimationType from '../animations/eAnimationType';
import AnimationLoader from '../animations/animationsLoader';
import { storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';

export default class ControllerSymbol extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.highAndSpecSymbols = [
      eSymbolType.EST_FROG,
      eSymbolType.EST_MONKEY,
      eSymbolType.EST_TOUCAN,
      eSymbolType.EST_SCATTER,
      eSymbolType.EST_WILD
    ];

    this.symbols = {};
    for (let key in eSymbolType) {
      const item = this.getChildByName(eSymbolType[key]);
      this.symbols[eSymbolType[key]] = item;
    }

    this.animationLow = AnimationLoader.createAnimation(eAnimationType.EAT_LOW_ICONS);
    this.animationLow.x = this.width / 2;
    this.animationLow.y = this.height / 2;
    this.animationLow.visible = false;
    this.animationLow.state.addListener({
      complete: () => {
        setTimeout(() => this.animationLow.visible = false, 1);
        this.symbols[this.type].visible = true;
      }
    });
    this.addChild(this.animationLow);

    this.animations = {};
    for (let i = 0; i < this.highAndSpecSymbols.length; i++) {
      const type = this.highAndSpecSymbols[i];
      const animation = AnimationLoader.createAnimation(type);
      if (type !== eSymbolType.EST_SCATTER && type !== eSymbolType.EST_WILD) {
        animation.x = 11;
        animation.y = 15;
        animation.scale.set(0.9);
      }
      if(type === eSymbolType.EST_WILD) {
        animation.state.timeScale = 2;
      }
      animation.visible = false;
      animation.state.addListener({
        complete: () => {
          setTimeout(() => animation.visible = false, 1);
          this.symbols[this.type].visible = true;
        }
      });
      this.addChild(animation);
      this.animations[type] = animation;
    }
    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);
    this.setSymbol(eSymbolType.EST_WILD);
  }

  setBlur(value) {
    // const frame = value ? 2 : 1;
    // for (let key in this.symbols) {
    //   this.symbols[key].goToFrame(frame);
    // }
  }

  onGameStateChanged = (state) => {
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      if (this.animations && this.animations[this.type]) this.animations[this.type].visible = false;
      if (this.animationLow) this.animationLow.visible = false;
      this.symbols[this.type].visible = true;
    }
  }

  setSymbol(type) {
    if (this.animations[this.type]) {
      const animation = this.animations[this.type];
      animation.state.clearTracks();
      animation.visible = false;
    }
    //todo: stop other animation here

    this.type = type;
    if (this.animationInterval) clearInterval(this.animationInterval);

    for (let key in this.symbols) {
      this.symbols[key].visible = key === type;
    }
  }

  animateSymbol() {
    this.symbols[this.type].visible = false;
    if (/low/.test(this.type)) {
      this.animationLow.visible = true;
      this.animationLow.state.setAnimation('0', this.type, false);

    } else if (this.highAndSpecSymbols.includes(this.type)) {
      this.animations[this.type].visible = true;
      this.animations[this.type].state.setAnimation('0', 'animation', false);
    } else {
      this.animationInterval = setInterval(() => {
        if (this.symbols[this.type])
          this.symbols[this.type].visible = !this.symbols[this.type].visible;
      }, 200);
      setTimeout(() => {
        if (this.animationInterval) clearInterval(this.animationInterval);
        this.symbols[this.type].visible = true;
      }, 1000)
    }
  }

}
