import FlashLib from 'flashlib';
import ControllerButton from './controllers/base/controllerButton';
import ModalBase from './controllers/base/modalBase';
import ControllerSlot from './controllers/controllerSlot/controllerSlot';
import ControllerReel from './controllers/reel/controllerReel';
import ControllerSymbol from './controllers/symbol/controllerSymbol';
import ModalSettings from './controllers/popups/modalSettings';
import ControllerSwitch from './controllers/base/controllerSwitch';
import ModalPaytable from './controllers/popups/modalPaytable';
import ModalBigWin from './controllers/popups/modalBigWin';
import ModalInfo from './controllers/popups/modalInfo';
import FreespinsPanel from './controllers/freespins/freespinsPanel';
import ControllerVines from './controllers/vines/controllerVines';
import ControllerBlackout from "./controllers/controllerBlackout";
import ModalFreeSpins from "./controllers/popups/modalFreeSpins";
import ModalFreeSpinsEnd from "./controllers/popups/modalFreeSpinsEnd";

FlashLib.registerClass('Button', ControllerButton);
FlashLib.registerClass('ControllerPopup', ModalBase);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerReel', ControllerReel);
FlashLib.registerClass('ControllerSymbol', ControllerSymbol);
FlashLib.registerClass('ModalSettings', ModalSettings);
FlashLib.registerClass('ModalPaytable', ModalPaytable);
FlashLib.registerClass('Switch', ControllerSwitch);
FlashLib.registerClass('ModalBigWin', ModalBigWin);
FlashLib.registerClass('ModalFreeSpins', ModalFreeSpins);
FlashLib.registerClass('ModalFreeSpinsEnd', ModalFreeSpinsEnd);
FlashLib.registerClass('ControllerBlackout', ControllerBlackout);
FlashLib.registerClass('ModalInfo', ModalInfo);
FlashLib.registerClass('FreespinsPanel', FreespinsPanel);
FlashLib.registerClass('ControllerVines', ControllerVines);
