import eSymbolType from './eSymbolType';

export default {
  "8": eSymbolType.EST_LOW_BLUE,
  "7": eSymbolType.EST_LOW_PURPLE,
  "6": eSymbolType.EST_LOW_GREEN,
  "5": eSymbolType.EST_LOW_ORANGE,
  "4": eSymbolType.EST_FROG,
  "3": eSymbolType.EST_MONKEY,
  "2": eSymbolType.EST_TOUCAN,
  "1": eSymbolType.EST_WILD,
  "9": eSymbolType.EST_SCATTER,
}
