import eModalType from './eModalType';
import ModalBase from '../base/modalBase';
import ControllerSounds from '../sounds/controllerSounds';

export default class ModalSettings extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_SETTINGS;

    this.initListener();
    this.initBlocker();
    this.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);

    this.switchSounds = this.getChildByName('switchSounds');
    this.switchSounds.addEventListenerSwitched(this.onSoundsSwitched);
    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);

    this.switchMusic = this.getChildByName('switchMusic');
    this.switchMusic.addEventListenerSwitched(this.onMusicSwitched);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);
  }

  onModalDataChanged = (data) => {
    this.visible = data.visible;

    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);
  };

  onSoundsSwitched = (value) => {
    ControllerSounds.setSoundsMuted(!value);
  };

  onMusicSwitched = (value) => {
    if (ControllerSounds.musicMuted) {
      ControllerSounds.unmuteSFX();
    } else {
      ControllerSounds.muteSFX();
    }
  };
}
