import ModalBase from "../base/modalBase";
import eModalType from "./eModalType";
import {modalWinHeadline, winAmountCounterStyle} from "../../fontStyles";
import {store} from "../../index";
import WinAmountCounter from "../winAmountCounter";
import actionsModals from "../../redux/modals/actions";

export default class ModalFreeSpins extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_FREE_SPINS;

    this.init();
  }

  init() {
    this.initListener();
    this.showBlackout();
    this.setTextStyle();
    this.on('pointertap', this.onClick);
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  setAmount(value) {
    this.winAmountCounter = new WinAmountCounter(this.getChildByName('winCounter'), winAmountCounterStyle);
    this.winAmountCounter.animate(value);
    this.winAmountCounter.onStop = () => store.dispatch(actionsModals.closeModal({ type: this.type }))
  }

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if(!blackout) return;
    blackout.show(true, 0.3);
  }

  setTextStyle() {
    const youWinText = this.getChildByName('youWin');
    const freeSPinsText = this.getChildByName('freeSpins');

    const localizationManager = window.OPWrapperService.localizations;
    youWinText.text = localizationManager.getLocalizedText('popup_you_win');
    freeSPinsText.text = localizationManager.getLocalizedText('popup_freespins');
    youWinText.style = freeSPinsText.style = modalWinHeadline;
    youWinText.fitSize(true, true);
    freeSPinsText.fitSize(true, true);
  }

  onClick() {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

  show(amount) {
    if(this.visible) return;

    this.visible = true;
    this.setAmount(amount);
  }

  hide() {
    this.visible = false;
  }

  onModalDataChanged = (data) => {
    const state = store.getState().game;
    data.visible ? this.show(state.freeSpinsMax) : this.hide();
  };
}
