import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import WinAmountCounter from "../winAmountCounter";
import {store} from "../../index";
import {winAmountCounterStyle, modalWinHeadline} from "../../fontStyles";
import actionsModals from "../../redux/modals/actions";
import ControllerSounds, {eSoundType} from "../sounds/controllerSounds";
import selectors from '../../redux/game/selectors';

// TODO: Need rename class, like AmountModal or something
export default class ModalBigWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_BIG;

    this.init();
  }

  init() {
    this.initListener();
    this.on('pointertap', this.onClick);
    document.addEventListener('keydown', event => this.onKeyDown(event), false);
  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  onModalDataChanged = (data) => {
    if(this.visible === data.visible) return;

    console.log('on modal data changed', data.visible);
    this.visible = data.visible;

    const state = store.getState();
    const winBig = state.modals.winBig;
    const betAmount = state.game.betAmount;
    const winAmount = winBig.data;
    let frameIndex = 1;

    if(!winBig.visible) return;

    ControllerSounds.playSound(eSoundType.EST_COINS);

    if(winAmount > 0) frameIndex = 4;
    if(winAmount >= (betAmount * 4) && betAmount >= 10) frameIndex = 1;
    if(winAmount >= (betAmount * 6) && betAmount >= 10) frameIndex = 2;
    if(winAmount >= (betAmount * 10) && betAmount >= 10) frameIndex = 3;

    this.setAmount(frameIndex, winAmount);
  };

  setAmount(frame, value) {
    this.goToFrame(frame);
    this.showBlackout();
    this.setTextStyle();

    let decimals = value < 10 ? 1 : 0;

    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    if (currencyInfo.decimal > 2) decimals = Math.min(value.getPrecision(), currencyInfo.decimal);

    this.winAmountCounter = new WinAmountCounter(this.getChildByName('winCounter'), winAmountCounterStyle);
    this.winAmountCounter.animate(value, decimals);
    this.winAmountCounter.onStop = () => {
      this.stop();
    };
  }

  showBlackout() {
    const blackout = this.getChildByName('blackout');
    if(!blackout) return;
    blackout.show(true, 0.3);
  }

  setTextStyle() {
    const youWinText = this.getChildByName('youWin');
    if(!youWinText) return;
    youWinText.text = window.OPWrapperService.localizations.getLocalizedText('popup_you_win');
    youWinText.style = modalWinHeadline;
  }

  onKeyDown(event) {
    if(event.keyCode === 32 && this.visible) { //32 - Space
      this.stop();
      this.winAmountCounter.stop();
    }
  }

  onClick() {
    this.stop();
    this.winAmountCounter.stop();
  }

  stop() {
    if(this.visible)
      store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

}
