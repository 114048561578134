import UiAdapter from './uiAdapter';
import { eSoundType } from '../sounds/controllerSounds';

const config = {
  H: {
    offsets: {
      right_panel: { top: 0, left: -11 },
      bottom_panel: { top: -48, left: 0 }
    }
  },
  V: {
    offsets: {
      top: -30,
      left: 0,
    }
  }
}

const initUi = () => {
  const ControllerUi = window.OPWrapperService.initUi(config);
  new UiAdapter(ControllerUi).init({
    clickSound: { soundName: eSoundType.EST_CLICK, volume: 0.7 },
  });
};

export {
  initUi,
}
