import ControllerSounds from './sounds/controllerSounds';
import { yellowStrokeGradient } from '../fontStyles';
import { store } from '../index';
import { eScreenMode } from '../enums';
import actions from '../redux/game/actions';
import { initUi } from './ui/initUi';

export default class ControllerStartScreen {
  constructor(main) {
    this.startScreen = main.getChildByName('startScreen');
    this.startScreen.interactive = true;
    this.label0 = this.startScreen.getChildByName('welcome_symbols');
    this.label1 = this.startScreen.getChildByName('welcome_closed_cells');

    this.label0.style = {
      ...this.label0.style,
      ...yellowStrokeGradient,
      stroke: "#793a14",
      strokeThickness: 12,
      letterSpacing: 1,
    };

    this.label1.style = {
      ...this.label1.style,
      ...yellowStrokeGradient,
      stroke: "#793a14",
      strokeThickness: 12,
      letterSpacing: 1,
    };

    this.buttonStart = this.startScreen.getChildByName('buttonStart');
    this.buttonStart.on('pointertap', this.onButtonStartClicked);
    const buttonLabel = this.buttonStart.getChildByName('welcome_button_start');
    buttonLabel.style = {
      ...buttonLabel.style,
      ...yellowStrokeGradient,
      stroke: "#793a14",
      strokeThickness: 12,
      letterSpacing: 1,
    }
  }

  onButtonStartClicked = () => {
    this.startScreen.visible = false;
    ControllerSounds.playSFX();
    ControllerSounds.onStartScreenHide();
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
    window.OPWrapperService.freeBetsController.show();
    initUi();
  };
}
