import selectors from "../../../redux/game/selectors";
import {store} from "../../../index";
import actions from '../../../redux/game/actions';
import {eGameState} from "../../../redux/game/reducer";
import {sleep} from "../../../utils/sleep";
import ControllerBaseBehaviour from './controllerBaseBehaviour';

export default class ControllerSlotNormalBehaviour extends ControllerBaseBehaviour {
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async delay => {
      await sleep(delay);
      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_SHOW_WIN));
    });
  }

}
