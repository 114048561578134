export default {
  EST_LOW_PURPLE: 'low_purple',
  EST_LOW_GREEN: 'low_green',
  EST_LOW_BLUE: 'low_blue',
  EST_LOW_ORANGE: 'low_orange',
  EST_TOUCAN: 'toucan',
  EST_FROG: 'frog',
  EST_MONKEY: 'monkey',
  EST_SCATTER: 'scatter',
  EST_WILD: 'wild'
}
