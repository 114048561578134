import ControllerSounds from '../sounds/controllerSounds';
import { eGameState } from '../../redux/game/reducer';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import modalSelectors from '../../redux/modals/selectors';
import eModalType from '../popups/eModalType';
import actions from '../../redux/game/actions';
import actionsModal from '../../redux/modals/actions';
import { eScreenMode } from '../../enums';
import { requestNewGame } from '../../api/rest';
import { sleep } from '../../utils/sleep';
import actionsModals from '../../redux/modals/actions';

export default class BaseUiAdapter {
  constructor(ControllerUi) {
    this._controllerUi = ControllerUi;
    this._currentOrientation = this._controllerUi.currentOrientation;
    this._uiVisible = true;
    this._uiVisibilityBlockers = new Set();

    this.initStateHandlers();
    this.initExtraStateHandlers();
    this.addListeners();
  }

  init(config) {
    this._initConfig = config || this._initConfig;
    this._bonusLabelText = this._initConfig.bonusLabelText;
    this._buttonClickSound = this._initConfig.clickSound;

    const currentBetIndex = storeObserver.useSelector(selectors.getBetIndex);
    const balance = storeObserver.useSelector(selectors.getBalance);
    const availableBets = storeObserver.useSelector(selectors.getAvailableBets);
    const isAutoSpinEnabled = storeObserver.useSelector(selectors.getIsAutoSpin);
    const lastWinAmount = storeObserver.useSelector(selectors.getLastWinAmount);
    const winAmount = storeObserver.useSelector(selectors.getWinAmount);

    this.syncAvailableBets(availableBets);
    this.ControllerUi.setCurrentBetIndex(currentBetIndex);
    this.ControllerUi.setBalance(balance);
    this.ControllerUi.setAutoPlayButtonActive(isAutoSpinEnabled);
    this.ControllerUi.setLastWin(lastWinAmount);
    this.ControllerUi.setWin(winAmount);

    let soundMuted = Boolean(ControllerSounds.soundsMuted) || Boolean(ControllerSounds.musicMuted);
    ControllerSounds.setSoundsMuted(soundMuted);
    soundMuted ? ControllerSounds.muteSFX() : ControllerSounds.unmuteSFX();
    this.ControllerUi.setSoundMuted(soundMuted);

    const isFreespinsEnabled = storeObserver.useSelector(selectors.getFreespinsEnabled);
    if (isFreespinsEnabled) {
      const freespinsCount = storeObserver.useSelector(selectors.getFreespinsCount);
      const freespinsTotalWin = storeObserver.useSelector(selectors.getFreespinsTotalWin);
      this.ControllerUi.showFreeSpins(freespinsCount, this._bonusLabelText);
      this.ControllerUi.showTotalWin(freespinsTotalWin);
    }

    this.baseOnStateChanged(storeObserver.useSelector(selectors.getGameState));

    if (this._uiVisible) {
      this.showUi();
    } else {
      this.hideUi();
    }

    this.addActions();
  }

  addActions() {
    const controllerTypes = this.ControllerUi.controllerTypes;
    const events = this.ControllerUi.events;

    if (events[controllerTypes.ECT_BET]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET].INCREASE_BUTTON_CLICK, this.onIncreaseBetClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET].DECREASE_BUTTON_CLICK, this.onDecreaseBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECTOR_TABLE]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECTOR_TABLE].CHANGE_BET_CLICK, this.onChangeBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECT]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, this.playClickSound.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, this.playClickSound.bind(this));
    }
    if (events[controllerTypes.ECT_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].SPIN_CLICK, this.onSpinClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].STOP_CLICK, this.onStopClick.bind(this));
    }
    if (events[controllerTypes.ECT_AUTO_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_AUTO_SPIN].AUTO_SPIN_CLICK, this.onAutoPlayClick.bind(this));
    }
    if (events[controllerTypes.ECT_MENU]) {
      this.ControllerUi.on(events[controllerTypes.ECT_MENU].BURGER_CLICK, this.onMenuClick.bind(this));
    }
    if (events[controllerTypes.ECT_SOUND]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_MUTE_CLICK, this.onSoundClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_UNMUTE_CLICK, this.onSoundClick.bind(this));
    }
    if (events[controllerTypes.ECT_INFO]) {
      this.ControllerUi.on(events[controllerTypes.ECT_INFO].INFO_CLICK, this.onPaytableClick.bind(this));
    }

    if (events[controllerTypes.ECT_MAX_BET]) {
      this.ControllerUi.on(events[controllerTypes.ECT_MAX_BET].MAX_BET_CLICK, this.onMaxBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_FULL_SCREEN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_FULL_SCREEN].FULL_SCREEN_CLICK, this.playClickSound.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_FULL_SCREEN].EXIT_FULL_SCREEN_CLICK, this.playClickSound.bind(this));
    }
  }

  addListeners() {
    const modalVisibilityListener = this.onModalVisibilityChange.bind(this);
    storeObserver.addListener(selectors.getGameState, this.baseOnStateChanged.bind(this));
    storeObserver.addListener(selectors.getIsAutoSpin, this.onAutoPlaySettingsActiveChanged.bind(this));
    storeObserver.addListener(selectors.getBetIndex, this.syncBet.bind(this));
    storeObserver.addListener(selectors.getAvailableBets, this.syncAvailableBets.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_WIN_BIG), this.onBigWinsVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_PAYTABLE), modalVisibilityListener);
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_WIN_FREE_SPINS), modalVisibilityListener);
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_WIN_FREE_SPINS_END), modalVisibilityListener);
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_INFO), modalVisibilityListener);
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_SETTINGS), modalVisibilityListener);
    ControllerSounds.onSoundSettingsCahnged.add(this.onSoundMuteChange.bind(this));
    if (!OPWrapperService.config.disableSpinBySpace) window.addEventListener('keydown', this.onKeyDown.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (window.OPWrapperService.UserAgentDetector.isMobile) {
      if (this._controllerUi.currentOrientation === this._currentOrientation) return;
      this._currentOrientation = this._controllerUi.currentOrientation;
      this.init();
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this._uiVisible) {
      if (this.ControllerUi.spinButtonEnabled) {
        return this.onSpinClick();
      }
      if (this.ControllerUi.stopButtonEnabled) {
        return this.onStopClick();
      }
    }
  }

  showUi() {
    this._uiVisible = true;
    this.ControllerUi.show();
  }

  hideUi() {
    this._uiVisible = false;
    this.ControllerUi.hide();
  }

  onBigWinsVisibilityChange({ type, visible }) {
    if (visible) {
      if (storeObserver.useSelector(selectors.getIsAutoSpin)) {
        this.ControllerUi.setOpacity(0.5);
      } else {
        this.tryHideUi(type);
      }
    } else {
      this.ControllerUi.setOpacity(1);
      this.tryShowUi(type);
    }
  }

  tryHideUi(controllerType) {
    this._uiVisibilityBlockers.add(controllerType);
    this.hideUi();
  }

  tryShowUi(controllerType) {
    this._uiVisibilityBlockers.delete(controllerType);
    if (!this._uiVisibilityBlockers.size) this.showUi();
  }

  onModalVisibilityChange({ type, visible }) {
    if (visible) {
      this.tryHideUi(type);
    } else {
      this.tryShowUi(type);
    }
  }

  syncBet(betIndex) {
    this.ControllerUi.setCurrentBetIndex(betIndex);
  }

  syncAvailableBets(bets) {
    this.ControllerUi.setAvailableBets(bets);
  }

  updateBalance(value) {
    if (value === undefined) value = storeObserver.useSelector(selectors.getBalance);
    this.ControllerUi.setBalance(value);
  }

  onSoundMuteChange() {
    let soundMuted = Boolean(ControllerSounds.musicMuted) && Boolean(ControllerSounds.soundsMuted);
    this.ControllerUi.setSoundMuted(soundMuted);
  }

  onSoundClick(muted) {
    ControllerSounds.setSoundsMuted(muted);
    muted ? ControllerSounds.muteSFX() : ControllerSounds.unmuteSFX();
  }

  onAutoPlayClick() {
    this.playClickSound();
    store.dispatch(actions.startAutospin());
    store.dispatch(actions.setGameState(eGameState.EGS_START_AUTOSPIN));
  }

  onStopClick() {
    this.playClickSound();
    this.ControllerUi.toggleStopButton(false);
    store.dispatch(actions.setGameState(eGameState.EGS_STOP_SPIN));
  }

  onAutoPlaySettingsActiveChanged(active) {
    this.ControllerUi.toggleStopButton(active);
  }

  onSpinClick() {
    if (window.OPWrapperService.model.data.isSpinBlocked) return;

    const gameScreen = store.getState().game.gameScreen;
    if (gameScreen !== eScreenMode.GAME) return;

    const state = store.getState();
    const amount = selectors.getBetAmount(state);
    const balance = selectors.getBalance(state);
    if (balance < amount && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    this.playClickSound();
    void requestNewGame();
    store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
  }

  onMaxBetClick() {
    this.playClickSound();
    store.dispatch(actions.setMaxBet());
  }

  onIncreaseBetClick() {
    this.playClickSound();
    store.dispatch(actions.incrementBet());
  }

  onDecreaseBetClick() {
    this.playClickSound();
    store.dispatch(actions.decrementBet());
  }

  onChangeBetClick(index) {
    this.playClickSound();
    store.dispatch(actions.setBet(index));
  }

  onMenuClick() {
    this.playClickSound();
    window.OPWrapperService.toggleSidebar();
  }

  onPaytableClick() {
    this.playClickSound();
    store.dispatch(actionsModal.showModal({ type: eModalType.EMT_PAYTABLE }));
  }

  playClickSound() {
    if (this._buttonClickSound) {
      ControllerSounds.playSound(this._buttonClickSound.soundName, this._buttonClickSound.volume);
    }
  }

  startAutospin = () => {
    const amount = storeObserver.useSelector(selectors.getBetAmount);
    const balance = storeObserver.useSelector(selectors.getBalance);
    if (balance < amount && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      // this is a hack to allow all functions bound to a gameState to be executed
      setTimeout(() => {
        store.dispatch(actions.stopAutospin());
        store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
      }, 1);
      return;
    }

    store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
    void requestNewGame();
  }

  baseOnStateChanged(state) {
    this.onStateChanged(state);
    if (OPWrapperService.config.skipBlocked && !storeObserver.useSelector(selectors.getIsAutoSpin) && ![eGameState.EGS_READY_TO_PLAY, eGameState.EGS_FREESPIN_MODE].includes(state)) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
    }
  }

  onStateChanged() {
    const state = store.getState().game;
    const gameState = state.gameState;
    console.log('Ui adapter notified by ', gameState, ' state');
    if (this.stateHandlers.hasOwnProperty(gameState)) {
      this.stateHandlers[gameState](store.getState().game);
    } else {
      this.stateHandlers.default(store.getState().game);
    }

    if (this.extraStateHandlers.hasOwnProperty(gameState)) {
      this.extraStateHandlers[gameState](store.getState().game);
    } else {
      this.extraStateHandlers.default(store.getState().game);
    }

    this.handleExtras(store.getState().game);
  }

  initStateHandlers() {
    this.stateHandlers = {
      [eGameState.EGS_READY_TO_PLAY]: async (state) => {
        const isFreeBets = window.OPWrapperService.freeBetsController.isActive || window.OPWrapperService.freeBetsController.isFirstFreeBet;

        if (state.freespinsEnabled || !state.autospin) {
          this.ControllerUi.showSpinButton();
        } else {
          this.ControllerUi.showStopButton();
        }

        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(!state.autospin);

        this.ControllerUi.toggleAutoPlayButton(!state.autospin && !state.freespinsEnabled && !isFreeBets);
        this.ControllerUi.toggleMenuButton(!state.autospin && !state.freespinsEnabled && !window.OPWrapperService.freeBetsController.isActive);
        this.ControllerUi.toggleInfoButton(!state.autospin && !state.freespinsEnabled && !window.OPWrapperService.freeBetsController.isActive);
        this.ControllerUi.toggleBetSelectors(!state.autospin && !state.freespinsEnabled && !isFreeBets);
        this.ControllerUi.toggleMaxBetButton(!state.autospin && !state.freespinsEnabled && !isFreeBets);
        this.ControllerUi.toggleTournamentsLabel(!state.autospin && !state.freespinsEnabled);
        this.ControllerUi.setBalance(state.balance);

        if (state.freespinsEnabled) {
          this.ControllerUi.showFreeSpins(state.freespinsCount, this._bonusLabelText);
          this.ControllerUi.showTotalWin();
          this.ControllerUi.setTotalWin(state.totalWinFS);
        } else {
          this.ControllerUi.hideFreeSpins();
          this.ControllerUi.hideTotalWin();
        }

        if (state.autospin) {
          if (state.winAmount != 0) {
            await sleep(2500);
          }

          store.dispatch(actions.setGameState(eGameState.EGS_START_AUTOSPIN));
        }
      },
      [eGameState.EGS_WAITING_FOR_RESPONSE]: (state) => {
        const isFreeBets = window.OPWrapperService.freeBetsController.isActive || window.OPWrapperService.freeBetsController.isFirstFreeBet;

        this.ControllerUi.closePopups();

        if (state.freespinsEnabled) {
          this.ControllerUi.showSpinButton();
          this.ControllerUi.showFreeSpins(state.freespinsCount, this._bonusLabelText);
          this.ControllerUi.showTotalWin();
          this.ControllerUi.setTotalWin(state.totalWinFS);
        } else {
          this.ControllerUi.showStopButton();
        }
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setWin(0);
        if (!OPWrapperService.freeBetsController.isActive && !state.freespinsEnabled) {
          this.ControllerUi.setBalance(state.balance);
        }
        if (state.freespinsEnabled) {
          this.ControllerUi.setFreeSpinsCount(state.freespinsCount - 1);
        } else {
          OPWrapperService.freeBetsController.decreaseFreeBets();
          OPWrapperService.ControllerStatistic.bet = state.betAmount;
        }

        if (!isFreeBets) window.OPWrapperService.ControllerStatistic.increaseSpinCount();
        OPWrapperService.realityCheck.blockedRealityCheck = true;
      },
      [eGameState.EGS_STOP_SPIN]: (state) => {
        if (state.autospin) {
          store.dispatch(actions.stopAutospin());
          store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
        }
      },
      [eGameState.EGS_CAN_STOP]: (state) => {
        if (state.autospin && state.freespinsEnabled) {
          store.dispatch(actions.stopAutospin());
          // store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
        }
        this.ControllerUi.toggleStopButton(true);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);

        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
      [eGameState.EGS_START_AUTOSPIN]: () => {
        this.startAutospin();
      },
      [eGameState.EGS_SHOW_WIN]: (state) => {
        if (state.freespinsEnabled) {
          this.ControllerUi.showSpinButton();
        } else {
          this.ControllerUi.showStopButton();
        }
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setWin(state.winAmount);

        if (state.winAmount != 0) {
          store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_BIG, data: state.winAmount }));
        }

        if (state.freeSpinsMax === 0) {
          store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
        }
      },
      default: () => {
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);
        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(true);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
    };
  }

  initExtraStateHandlers() {
    if (!this.extraStateHandlers) {
      this.extraStateHandlers = {
        default: () => {
        }
      };
    }
  }

  handleExtras(state) {
    this.ControllerUi.setAutoPlayButtonActive(state.autospin);

    if (state.autospin) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(true);
      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
    }
  }

  get ControllerUi() {
    return this._controllerUi.hud;
  }
}
