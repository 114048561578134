import { handleActions } from 'redux-actions';

import actions from './actions';
import serverClientSymbols from '../../controllers/symbol/serverClientSymbols';
import { getUrlParams } from '../../utils/url';
import { eScreenMode } from '../../enums';

export const eGameState = {
  EGS_READY_TO_PLAY: 'ready_to_play',
  EGS_WAITING_FOR_RESPONSE: 'waiting_for_response',
  EGS_CAN_STOP: 'can_stop',
  EGS_START_AUTOSPIN: 'start_autospin',
  EGS_STOP_AUTOSPIN: 'stop_autospin',
  EGS_STOP_SPIN: 'stop_spin',
  EGS_SHOW_WIN: 'show_win',
  EGS_FREESPIN_MODE: 'freespin_mode'
};

let betAmountStep = 0.00000001;
const initialState = {
  betAmount: 1,
  currency: 'RUB',
  round_hash: '',
  availableSymbols: [],
  winAmount: 0,
  lastWinAmount: 0,
  bets: [],
  betsAvailable: [],
  freeBetsAvailable: [],
  autospin: false,
  // user state
  balance: 0.5,
  gameState: eGameState.EGS_READY_TO_PLAY,
  betMin: 1,
  betMax: 100,
  freespinsEnabled: false,
  freespinsMode: false,
  freespinsCount: 0,
  freeSpinsMax: 0,
  freespinsSymbols: [],
  currencyInfo: {
    symbol: '₽',
    decimal: 2,
    currency: 'rub',
    balance: 0,
  },
  gameScreen: eScreenMode.START
};

const setData = (state, { payload }) => {
  convertToClientSymbols(payload.available_icons);

  const paytable = {};
  for (let key in payload.paytable.coefs) {
    paytable[serverClientSymbols[key]] = payload.paytable.coefs[key];
  }

  const balance = payload.balance
  const betsAvailable = payload.bets_available;
  let maxIndex = betsAvailable.filter(b => b <= balance).length - 1;
  if (maxIndex <= 0) maxIndex = 0;
  const pid = getUrlParams(window.location.search).pid;
  const defaultBetIndex = pid === '217' ? 0 : Math.min(betsAvailable.length - 2, maxIndex); //217 - PM Belarus
  const serverBetIndex = typeof(payload.model.betAmount) === 'number' && betsAvailable.indexOf(payload.model.betAmount) !== -1 && betsAvailable.indexOf(payload.model.betAmount);
  let currentBetIndex = typeof(serverBetIndex) === 'number' ?  serverBetIndex : defaultBetIndex;
  let betAmount = betsAvailable[currentBetIndex];
  let bets = betsAvailable;

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  if (payload.decimal) {
    console.warn('decimal field will deprecated soon in config request');
  }
  return {
    ...state,
    balance,
    availableSymbols: payload.available_icons,
    freeBetsAvailable,
    betsAvailable,
    bets,
    betMin: bets[0],
    betMax: bets[bets.length - 1],
    currentBetIndex,
    betAmount,
    paytable,
    freespinsEnabled: payload.model.freeSpinsCount > 0,
    freespinsCount: payload.model.freeSpinsCount,
    freeSpinsMax: payload.model.freeSpinsMaxCount,
    totalWinFS: payload.model.totalWin,
    currencyInfo: {
      symbol: payload.currency,
      //todo: remove decimal in next release
      decimal: payload.decimal || payload.decimals,
      currency: payload.currency,
      balance: payload.balance,
    }
  };
};

const setNewGame = (state, { payload }) => {
  const winLines = [];
  for (const lineInfo of payload.spin.result) {
    winLines.push(lineInfo.ind);
  }
  let bets = state.betsAvailable;

  let betAmount = payload.hasOwnProperty('bet_amount')
    ? payload.bet_amount
    : payload.hasOwnProperty('bet_index')
      ? state.betsAvailable[payload.bet_index]
      : state.betAmount;

  let currentBetIndex = payload.hasOwnProperty('bet_index')
    ? payload.bet_index
    : payload.hasOwnProperty('bet_amount')
      ? state.betsAvailable.indexOf(payload.bet_amount)
      : state.currentBetIndex;

  const winAmount = payload.spin.total_win || 0

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  window.OPWrapperService.ControllerStatistic.win = winAmount;

  const freespinsEnabled = payload.spin.isFreeSpins;
  return {
    ...state,
    freeBetsAvailable,
    bets,
    betAmount,
    currentBetIndex,
    freespinsEnabled,
    winAmount,
    gameState: eGameState.EGS_CAN_STOP,
    lastWinAmount: state.winAmount || state.lastWinAmount,
    stopReels: payload.spin.reels,
    winLine: winLines,
    balance: payload.balance || 0,
    freespinsCount: payload.spin.freeSpins,
    freeSpinsMax: payload.spin.freeSpinsMax,
    totalWinFS: payload.total_win
  };
};

const incrementBet = (state) => {
  const step = state.currentBetIndex + 1;
  if (step >= state.bets.length) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  }
};

const decrementBet = (state) => {
  const step = state.currentBetIndex - 1;
  if (step < 0) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  }
};

const setMinBet = (state) => {
  return {
    ...state,
    betAmount: state.betMin,
    currentBetIndex: 0,
  }
};

const setMaxBet = (state) => {
  const bets = state.bets;
  const index = bets.length - 1;
  const bet = bets[index] ? bets[index] : state.bets[0];

  return {
    ...state,
    betAmount: bet,
    currentBetIndex: index,
  }
};

const setBet = (state, index) => {
  if (!state.bets[index.payload]) return;
  return {
    ...state,
    currentBetIndex: index.payload,
    betAmount: state.bets[index.payload]
  };
};

const setCurrencyInfo = (state, { payload }) => {
  //todo: ask Vova to send balance with floating point
  return ({
    ...state,
    currency: payload.currency.toUpperCase(),
    balance: payload.balance / Math.pow(10, payload.decimal),
    currencyInfo: payload
  })
};

const convertToClientSymbols = (array) => {
  for (let i = 0; i < array.length; i++) {
    const reel = array[i];
    for (let j = 0; j < reel.length; j++) {
      reel[j] = serverClientSymbols[reel[j]];
    }
  }
};

export default handleActions(
  {
    [actions.setData]: setData,
    [actions.setNewGame]: setNewGame,
    [actions.incrementBet]: incrementBet,
    [actions.decrementBet]: decrementBet,
    [actions.setMinBet]: setMinBet,
    [actions.setMaxBet]: setMaxBet,
    [actions.setBet]: setBet,
    [actions.setCurrencyInfo]: setCurrencyInfo,
    [actions.setGameState]: (state, { payload }) => {
      console.log('Current state: ', payload);
      return { ...state, gameState: payload };
    },
    [actions.setBalance]: (state, { payload }) => ({ ...state, balance: payload }),
    [actions.startAutospin]: (state) => ({ ...state, autospin: true }),
    [actions.stopAutospin]: (state) => ({ ...state, autospin: false }),
    [actions.setFreespinMode]: (state, { payload }) => ({ ...state, freespinsMode: payload }),
    [actions.setScreenMode]: (state, { payload }) => ({ ...state, gameScreen: payload })
  },
  initialState,
);
