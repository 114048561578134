import { eSoundType } from './controllerSounds';
import * as PIXI from 'pixi.js';

export default new class SoundsPreloader {
  constructor() {

  }

  startLoading() {
    for (const key in eSoundType) {
      const value = eSoundType[key];
      PIXI.Loader.shared.add(value, `assets/sounds/${value}.mp3`);
    }

  }
}
