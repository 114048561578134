import eAnimationType from './eAnimationType';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_LOW_ICONS, src: 'assets/animations/low_icons/low_icons.json' },
      { type: eAnimationType.EAT_FROG, src: 'assets/animations/high_icons/frog.json' },
      { type: eAnimationType.EAT_TIGER, src: 'assets/animations/high_icons/tiger.json' },
      { type: eAnimationType.EAT_TOUCAN, src: 'assets/animations/high_icons/tucan.json' },
      { type: eAnimationType.EAT_MONKEY, src: 'assets/animations/high_icons/monkey.json' },
      { type: eAnimationType.EST_WILD, src: 'assets/animations/high_icons/panther.json' },
      { type: eAnimationType.EST_SCATTER, src: 'assets/animations/specials/sabers.json' },
      { type: eAnimationType.EST_VINES, src: 'assets/animations/vines/vines.json' },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      app.loader.add(item.type, item.src);
    }
    app.loader.load(this.onAnimationLoaded);
  }

  onAnimationLoaded = (_, resources) => {
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();
