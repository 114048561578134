import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import Event from '../../utils/event';

const eAnimationName = {
  EAN_CUT: 'cut',
  EAN_GROW: 'grow',
};

export default class ControllerVines extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.isHide = false;

    this.onAnimationHideStart = new Event();
    this.onAnimationShowComplete = new Event();

    this.animation = animationsLoader.createAnimation(eAnimationType.EST_VINES);
    this.animation.stateData.setMix(eAnimationName.EAN_CUT, eAnimationName.EAN_GROW, 0.1);
    this.animation.state.addListener({
      start: this._onAnimationStart,
      complete: this._onAnimationComplete,
    });
    this.animation.x = -this.x;
    this.animation.y = -this.y;
    this.getChildAt(0).visible = false;
    this.addChild(this.animation);
  }

  show(delay) {
    if(!this.isHide) return;
    this.isHide = false;
    this.animation.state.clearTracks();

    //todo: this is hack
    setTimeout(() => {
      this.animation.skeleton.setToSetupPose();
      const track = this.animation.state.addAnimation(0, eAnimationName.EAN_GROW, false, 0);
      track.timeScale = 2;
    }, delay)
  }

  hide(delay) {
    if(this.isHide) return delay;
    this.isHide = true;

    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.EAN_CUT, false, delay / 1000);

    const duration = this.animation.state.tracks[0].animation.duration;
    return delay + duration * 1000;
  }

  _onAnimationStart = (e) => {
    if(e.animation.name === eAnimationName.EAN_CUT) {
      console.log('onAnimationHideStart')
      this.onAnimationHideStart.call();
    }
  };

  _onAnimationComplete = (e) => {
    if(e.animation.name === eAnimationName.EAN_GROW) {
      console.log('onAnimationShowComplete')
      this.onAnimationShowComplete.call();
    }
  };
}
