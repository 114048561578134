export const getRandomNumbers = (count) => {
  let result = '';
  for (let i = 0; i < count; i++) {
    result += Math.round(Math.random() * 9);
  }

  return result;
};

export const round = (value, numbersAfterPoint = 0) => {
  const divider = Math.pow(10, numbersAfterPoint);
  let newValue = Math.round(value * divider) / divider;

  newValue = parseFloat(newValue).toFixed(numbersAfterPoint);

  return newValue;
};

