import ModalBase from '../base/modalBase';
import eModalType from './eModalType';

export default class ModalInfo extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);
    this.type = eModalType.EMT_INFO;
    this.label = this.getChildByName('label');
    this.label.anchor.set(0.5);
    this.label.x = this.label.width / 2 - this.label.x;
    this.label.y += this.label.height / 2;
    this.getChildByName('buttonOk').addListener('pointertap', this.onCloseClicked);
    this.initListener();
  }

  onModalDataChanged = (data) => {
    this.visible = data.visible;
    if(data.data) this.label.text = data.data;
  };
}
