export default {
  EAT_COINS: 'oc coins',
  EAT_CELL: 'fish and pearl',
  EAT_LOW_ICONS: 'low_icons',
  EAT_TOUCAN: 'toucan',
  EAT_TIGER: 'tiger',
  EAT_FROG: 'frog',
  EAT_MONKEY: 'monkey',
  EST_SCATTER: 'scatter',
  EST_VINES: 'vines',
  EST_WILD: 'wild'
}
