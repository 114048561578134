import { getFromStorage, setToStorage } from '../../utils/localStorage';
import SoundManager from './SoundManager';
import Event from '../../utils/event';

export const eSoundType = {
  EST_BIG_WIN: 'bigWin',
  EST_CLICK: 'click',
  EST_COINS: 'coins',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
  EST_REEL_STOP: 'reelStop',
};

class ControllerSounds {

  constructor() {
    this.musicMuted = getFromStorage('musicMuted');
    if (this.musicMuted === null) {
      setToStorage('musicMuted', 'false');
      this.musicMuted = false;
    }

    this.soundsMuted = getFromStorage('soundsMuted');
    if (this.soundsMuted === null) {
      setToStorage('soundsMuted', 'false');
      this.soundsMuted = false;
    }

    this.onSoundSettingsCahnged = new Event();
  }

  onStartScreenHide = () => {
    this.playSFX();
  };

  playSound(type) {
    if (this.soundsMuted) return;
    return SoundManager.play(type, 0.5, false);
  }

  stopSound(type) {
    SoundManager.stop(type);
  }

  muteSFX() {
    this.musicMuted = true;
    setToStorage('musicMuted', 'true');
    this.pauseSFX();
    this.onSoundSettingsCahnged.call();
  }

  unmuteSFX() {
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    this.playSFX();
    this.onSoundSettingsCahnged.call();
  }

  setSoundsMuted(value) {
    this.soundsMuted = value;
    setToStorage('soundsMuted', value);
    this.onSoundSettingsCahnged.call();
  }

  playSFX = () => {
    if (this.musicMuted) return;

    this.musicMuted = false;
    setToStorage('musicMuted', 'false');

    SoundManager.play(eSoundType.EST_SFX, 0.3, true);
  };

  pauseSFX() {
    SoundManager.pause(eSoundType.EST_SFX, 0.3, true);
  }
}

export default new ControllerSounds();
