import { store, storeObserver } from '../../index';
import actions from '../../redux/game/actions';
import modalActions from '../../redux/modals/actions';
import eModalType from '../../controllers/popups/eModalType';
import controllerSounds from '../../controllers/sounds/controllerSounds';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));

    this.freespinsStarted = false;

    controllerSounds.onSoundSettingsCahnged.add(this.audioToggle.bind(this));
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {
    store.dispatch(actions.stopAutospin());
  }

  setAudio(value) {
    if (value) {
      controllerSounds.unmuteSFX();
    } else {
      controllerSounds.muteSFX();
    }
    controllerSounds.setSoundsMuted(!value);
  }

  showHelp() {

  }

  togglePaytable() {
    const visible = store.getState().modals[eModalType.EMT_PAYTABLE].visible;
    if (visible) {
      store.dispatch(modalActions.closeModal({ type: eModalType.EMT_PAYTABLE }));
    } else {
      store.dispatch(modalActions.showModal({ type: eModalType.EMT_PAYTABLE }));
    }
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    const audioState = !controllerSounds.musicMuted && !controllerSounds.soundsMuted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
    this.stakeUpdate();
  }

  audioToggle() {
    const audioState = !(controllerSounds.musicMuted && controllerSounds.soundsMuted);
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
  }

  stakeUpdate() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, store.getState().game.betAmount);
  }

  stateUpdated() {
    const game = store.getState().game;
    if (game.gameState === eGameState.EGS_WAITING_FOR_RESPONSE && !this.freespinsStarted) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
      this.freespinsStarted = game.freespinsEnabled;
    }

    if (game.gameState === eGameState.EGS_READY_TO_PLAY) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
      this.freespinsStarted = false;
    }

    if (game.gameState === eGameState.EGS_CAN_STOP) {
      this.freespinsStarted = game.freespinsEnabled;
    }
  }

  closed() {

  }
}
