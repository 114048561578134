import FlashLib from 'flashlib';
import Constants from '../../constants';
import serverClientSymbols from '../symbol/serverClientSymbols';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import ControllerSlotNormalBehaviour from './behaviour/controllerSlotNormalBehaviour';
import ControllerSlotStartFreespinsBehaviour from './behaviour/controllerSlotStartFreespinsBehaviour';
import ControllerSlotFreespinsBehaviour from './behaviour/controllerSlotFreespinsBehaviour';
import ControllerSlotLastFreespinBehaviour from './behaviour/controllerSlotLastFreespinBehaviour';
import { sleep } from '../../utils/sleep';


export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.reels = [];
    const mask = this.getChildByName('maskArea');
    const reels = this.getChildByName('reelsArea');
    reels.mask = mask;

    for (let i = 0; i < Constants.REELS_COUNT; i++) {
      const item = reels.getChildByName(`reel_${i}`);
      item.visible = !(i > 4);
      item.setupAnimations(0.1 * i);
      this.reels.push(item);
    }

    this.vines = this.getChildByName('vines');
    this.vines.onAnimationShowComplete.add(() => {
      this.reels[5].visible = false;
      this.reels[6].visible = false;
    });
    this.vines.onAnimationHideStart.add(() => {
      this.reels[5].visible = true;
      this.reels[6].visible = true;
    });

    this.animationTimeouts = [];
    this.lineIndex = 0;
    this.forceStopped = false;
    this.isScatterDrop = false;
    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);

    this.freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    if (this.freespinsEnabled) {
      this.vines.hide(0);
    }

    this._behaviour = new ControllerSlotNormalBehaviour(this);
  }

  onGameStateChanged = (state) => {
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      this.spin();
    } else if (state === eGameState.EGS_CAN_STOP) {
      this.stop();
    } else if (state === eGameState.EGS_STOP_SPIN && !store.getState().game.autospin) {
      this.forceStop();
    }
  };

  spin() {
    this.lineIndex = 0;
    this.isScatterDrop = false;
    this.forceStopped = false;
    this.clearTimeouts();
    this.freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    const reelsCount = this.freespinsEnabled ? 7 : 5;

    for (let i = 0; i < reelsCount; i++) {
      this.reels[i].spin();
    }
  }

  async stop() {
    this.clearTimeouts();
    const line = selectors.getStopReels(store.getState());
    const symbolId = line[this.lineIndex];
    const symbolType = serverClientSymbols[symbolId];
    const callback = this.lineIndex === line.length - 1 ? this.onStopAnimationCompleted : () => {};

    this.reels[this.lineIndex].stop(symbolType, symbolId, callback, this.checkAnticipator.bind(this));
  }

  forceStop() {
    // this.clearTimeouts();

    this.forceStopped = true;
    this.lineIndex = 0;

    const line = selectors.getStopReels(store.getState());
    for (let i = 0; i < line.length; i++) {
      const symbolType = serverClientSymbols[line[i]];
      const callback = i === line.length - 1 ? this.onStopAnimationCompleted : () => {};

      this.reels[i].forceStop(symbolType, callback);
    }

    this.reels.forEach(reel => this.disableAnticipator(reel));
  }

  async checkAnticipator(reel) {
    const line = selectors.getStopReels(store.getState());
    const scatters = line.filter(l => l === 8).length;

    let needSleep = false;

    if(line[this.lineIndex] === 8) {
      this.isScatterDrop = true;
    }

    if(this.isScatterDrop) {
      // Play sound
      const state = store.getState().game;
      let canPlaySound = false;

      for(let i = this.lineIndex; i < line.length - 1; i++) {
        if(this.lineIndex === 3 && scatters <= 1) {
          this.reels[i].showAnticipator(false);
          canPlaySound = false;
        }
        else if(this.lineIndex === 3 && scatters == 2 && line[this.lineIndex + 1] === 8) {
          this.reels[i].showAnticipator(false);
          canPlaySound = false;
        }
        else {
          this.reels[i].showAnticipator(state.gameState != eGameState.EGS_STOP_SPIN);
          needSleep = true;
          canPlaySound = true;
        }
      }

      // if(canPlaySound && !this.forceStopped) ControllerSounds.playSound(eSoundType.EST_ANTICIPATOR);
    }

    if(this.reels[this.lineIndex - 1]) this.reels[this.lineIndex - 1].showAnticipator(false);

    this.lineIndex += 1;

    // if(needSleep) await sleep(2500);

    if(this.lineIndex < line.length) this.stop();
    else this.lineIndex = 0;
  }

  disableAnticipator(reel) {
    // ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);
    reel.showAnticipator(false);
  }

  onStopAnimationCompleted = async (reel) => {

    const state = store.getState().game;

    this.reels.forEach(reel => this.disableAnticipator(reel));

    if (state.freespinsEnabled && state.freespinsCount === state.freeSpinsMax) {
      this._behaviour = new ControllerSlotStartFreespinsBehaviour(this);
    } else if (state.freeSpinsMax > 0 && state.freespinsCount === 0) {
      this._behaviour = new ControllerSlotLastFreespinBehaviour(this);
    } else if (state.freeSpinsMax > 0 && state.freespinsCount !== 0) {
      this._behaviour = new ControllerSlotFreespinsBehaviour(this);
    } else {
      this._behaviour = new ControllerSlotNormalBehaviour(this);
    }

    await sleep(200);
    this._behaviour.endSpin();
  };

  async startWinAnimation(winLines, firstRun) {
    let delay = 0;

    winLines.forEach(line => {
      const timeout = setTimeout(() => {
        // if (firstRun) ControllerSounds.playSound(eSoundType.EST_WIN);
        line.forEach(index => this.reels[index].playSymbolAnimation());
      }, delay);
      delay += 2000;
      this.animationTimeouts.push(timeout);
    })

    if (!this.freespinsEnabled) {
      const timeout = setTimeout(() => {
        this.startWinAnimation(winLines, false)
      }, delay);
      this.animationTimeouts.push(timeout);
    }

    return delay;
  }

  clearTimeouts() {
    for (let i in this.animationTimeouts) {
      clearTimeout(this.animationTimeouts[i]);
      delete this.animationTimeouts[i];
    }
    this.animationTimeouts.length = 0;
  }
}
