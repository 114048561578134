// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/UBUNTU-B.TTF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/UBUNTU-R.TTF");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/MYRIADPRO-BOLDCOND.OTF");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/Inter/Inter-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/Inter/Inter-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/Inter/Inter-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Ubuntu bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Ubuntu regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Myriad Pro\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Inter Semi Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  font-display: block;\n}", ""]);
// Exports
module.exports = exports;
