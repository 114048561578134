import Event from '../utils/event';

export default class StoreObserver {
  constructor(store) {
    this.store = store;
    this.debugEnabled = false;
    this.store.subscribe(this.onStoreChanged);
    this.prevState = null;
    this.currentState = store.getState();
    this.selectors = {};
    this.callStack = {};
  }

  onStoreChanged = () => {
    this.prevState = this.currentState;
    this.currentState = this.store.getState();

    for (let key in this.selectors) {
      let item = this.selectors[key];
      const prevValue = item.check(this.prevState);
      const newValue = item.check(this.currentState);
      if (this.debugEnabled)
        console.log('Store observer\n' +
          `prevValue: ${prevValue}\n` +
          `newValue: ${newValue}\n` +
          `Not same link: ${prevValue !== newValue}\n` +
          `Not same content: ${(JSON.stringify(prevValue) !== JSON.stringify(newValue))}\n` +
          `Events count: ${item.events._listeners.length}`);
      if (prevValue !== newValue) {
        if (key in this.callStack) {
          while (this.callStack[key].length) {
            const itemFromStack = this.callStack[key].shift();
            itemFromStack.events.call(prevValue);
          }
        } else {
          this.callStack[key] = [];
        }

        this.callStack[key].push(item);
      }

      for (let key in this.callStack) {
        while (this.callStack[key].length) {
          const stackItem = this.callStack[key].shift();
          stackItem.events.call(newValue);
        }
      }
    }
  };

  addListener = (check, callback) => {
    if (!this.selectors[check]) {
      this.selectors[check] = { check: check, events: new Event() };
    }
    this.selectors[check].events.add(callback);
  };

  addListenerAsNew = (check, callback) => {
    let newKey = check.toString();
    if (this.selectors[check]) {
      newKey += Math.random();
    }
    this.selectors[newKey] = { check: check, events: new Event() };
    this.selectors[newKey].events.add(callback);
  };

  addListenerAndGetValue = (check, callback) => {
    this.addListener(check, callback);

    return check(this.store.getState());
  };

  addListenerAsNewAndGetValue = (check, callback) => {
    this.addListenerAsNew(check, callback);

    return check(this.store.getState());
  };

  removeListener = (check, callback) => {
    if (!this.selectors[check]) {
      return;
    }

    this.selectors[check].events.remove(callback);
  }

  useSelector = (selector) => {
    return selector(this.store.getState());
  }
}
